export default class Note {
  constructor() {
    this.id = null;
    this.title = null;
    this.body = null;
    this.importance = null;
    this.url = null;
    this.description = null;
    this.owner = null;
  }
}
