var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.note.add_note'),"icon":"tw-hr-cost"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.title'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.note.title'),"color":"tertiary","clearable":"","error-messages":errors,"counter":"255"},model:{value:(_vm.note.title),callback:function ($$v) {_vm.$set(_vm.note, "title", $$v)},expression:"note.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.body'),"rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"2","label":_vm.$t('hr.note.body'),"color":"tertiary","clearable":"","error-messages":errors,"counter":"512"},model:{value:(_vm.note.body),callback:function ($$v) {_vm.$set(_vm.note, "body", $$v)},expression:"note.body"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.owner'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('employee-picker',{attrs:{"label":_vm.$t('hr.note.owner'),"error-messages":errors},model:{value:(_vm.note.owner),callback:function ($$v) {_vm.$set(_vm.note, "owner", $$v)},expression:"note.owner"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.importance'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('importance-picker',{attrs:{"clearable":"","error-messages":errors},model:{value:(_vm.note.importance),callback:function ($$v) {_vm.$set(_vm.note, "importance", $$v)},expression:"note.importance"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('file-upload-picker',{attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.note.attachment'),"storageType":"NOTE","onCreate":""},on:{"send":function (cb) { return (_vm.onFileSend = cb); }},model:{value:(_vm.note.url),callback:function ($$v) {_vm.$set(_vm.note, "url", $$v)},expression:"note.url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.description'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"1","label":_vm.$t('hr.note.description'),"color":"tertiary","clearable":"","error-messages":errors,"counter":"512"},model:{value:(_vm.note.description),callback:function ($$v) {_vm.$set(_vm.note, "description", $$v)},expression:"note.description"}})]}}])})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{attrs:{"create":""},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }